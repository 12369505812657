.Notes {
  background-color: rgb(248, 248, 248);
  padding: 1px 10px 10px;
  border: 1px #aba3a3 solid;
  border-top: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Notes .not-found {
  text-align: center;
  padding: 1px 10px 30px;
}

.Notes h3 {
  margin: 0px;
  padding-left: 5px;
  font-family: "Open Sans", sans-serif;
}

.Notes form textarea {
  height: 300px;
}

.Notes .spinning2.glyphicon {
  margin-top: 10px;
  font-size: xx-large;
  display: block;
  text-align: center;
  animation: spin 1s infinite linear;
}

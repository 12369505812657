.NewNote {
  background-color: rgb(248, 248, 248);
  padding: 1px 10px 10px;
  border: 1px #aba3a3 solid;
  border-top: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.NewNote form textarea {
  height: 300px;
}

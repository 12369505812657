.ResetPassword {
    background-color: rgb(248, 248, 248);
    border: 1px #aba3a3 solid;
    border-top: 0;
    padding: 40px 0;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

@media all and (min-width: 480px) {
    .ResetPassword form {
      margin: 0 auto;
      max-width: 320px;
    }
  
    .ResetPassword .success {
      max-width: 400px;
    }
  }
  
  .ResetPassword .success {
    margin: 0 auto;
    text-align: center;
  }
  .ResetPassword .success .glyphicon {
    color: grey;
    font-size: 30px;
    margin-bottom: 30px;
  }
  
.App {
  margin-top: 15px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif !important;
}

.App .navbar-brand {
  font-weight: bold;
}

.top {
  border: 1px #aba3a3 solid;
  border-bottom: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
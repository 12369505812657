.Home {
  padding-bottom: 10px;
  border: 1px #aba3a3 solid;
  border-top: 0;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgb(248, 248, 248);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Home .lander {
  font-size: 16px;
  line-height: 1.7;
  background-color: rgb(248, 248, 248);
  padding: 1px 15px 15px;
}

.Home .lander h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}

.Home .notes {
  padding: 1px;
}

.Home .notes .container-fluid {
  padding: 0px;
}

.Home .notes h2 {
  font-family: "Open Sans", sans-serif;
}

.Home .notes h4 {
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
  margin: 0;
  color: #333;
  line-height: 1.5;
  display: -webkit-box;
  text-overflow: ellipsis;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
}

.Home .notes .description {
  border: 0;
  padding-left: 15px;
  padding-right: 15px;
  color: #666;
  vertical-align: middle;
}

.Home .notes .issue {
  cursor: pointer;
}

.Home .notes .issue:hover {
  background-color: #F5F5F5;
}

.Home .notes .dropdown {
  vertical-align: middle;
  width: 40px;
}

.Home .notes td {
  border: 0;
}

.Home .Notes {
  border: 0;
  padding: 0;
}

.Home .notes .spinning.glyphicon {
  margin-top: 10px;
  font-size: xx-large;
  display: block;
  text-align: center;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.bs-example {
  color: black;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.navbar {
  margin-bottom: 0px;
  border-radius: 0px;
  border: 0px;
}

.notes .navbar {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.btn-group .btn {
  border-radius: 0px;
}

.navbar-form {
    float: right;
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.list-group-item {
  padding: 0px;
}

.list-group {
  margin-bottom: 0px;
}

.breadcrumb>.active {
  color: black;
}
.About .lander {
  font-size: 16px;
  line-height: 1.7;
  background-color: rgb(248, 248, 248);
  padding: 1px 15px 15px;
  border: 1px #aba3a3 solid;
  border-top: 0;
  background-color: rgb(248, 248, 248);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.About .lander h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.About .lander p {
  color: #999;
}

.About .lander div {
  padding-top: 20px;
}
.About .lander div a:first-child {
  margin-right: 20px;
}
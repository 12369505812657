.Login {
  background-color: rgb(248, 248, 248);
  border: 1px #aba3a3 solid;
  padding: 40px 0;
  padding-left: 10px;
  padding-right: 10px;
  border-top: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media all and (min-width: 480px) {
  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Login .forgot-password {
  margin-bottom: 15px;
  display: block;
  font-size: 14px;
}
